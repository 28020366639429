import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  asics: [],
  sharedAsics: [],
  bills: [],
  payouts: [],
  currentBundle: {},
  cart: { bundles: [], total: 0 },
  isLogoutModalOpen: false,
  totalMined: {
    btc: 0,
    kda: 0,
  },
  idToken: "",
  user: {},
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setAsics: (state, action) => {
      state.asics = action.payload;
    },
    setSharedAsics: (state, action) => {
      state.sharedAsics = action.payload;
    },
    setBills: (state, action) => {
      state.bills = action.payload;
    },
    setTotalMined: (state, action) => {
      state.totalMined = action.payload;
    },
    setPayouts: (state, action) => {
      state.payouts = action.payload;
    },
    setBundles: (state, action) => {
      state.cart.bundles = action.payload;
      let prices = action.payload.map((b) => b.price);
      let total = prices.reduce((a, b) => a + b, 0);
      state.cart.total = Math.floor(total * 100) / 100;
    },
    setLogoutModalOpen: (state, action) => {
      state.isLogoutModalOpen = action.payload;
    },
    setIdToken: (state, action) => {
      state.idToken = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  setAsics,
  setSharedAsics,
  setBills,
  setPayouts,
  setBundles,
  setLogoutModalOpen,
  setTotalMined,
  setIdToken,
  setUser,
} = mainSlice.actions;
export default mainSlice.reducer;
