import { Box, Button, Modal, Typography } from "@mui/material";
import * as PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.main",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const LogoutModal = (props) => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_BASE_URL + "/login",
      },
    });
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box display="flex" flexDirection="column" gap="1rem" sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          This will close your session. Are you sure you want to log out?
        </Typography>
        <Button onClick={handleLogout}>Log out</Button>
      </Box>
    </Modal>
  );
};

LogoutModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
