import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

const ElectricityPlanCard = ({ electricityPlanModel }) => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const routeParams = useParams();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const idToken = useSelector((state) => state.eph.main.idToken);

  const createBundle = async () => {
    const data = {
      asic_farm: routeParams.id_asic_farm,
      electricity_plan_model: electricityPlanModel.id_electricity_plan_model,
    };
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/bundles/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRFToken": cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setSuccess("Added to cart.");
    } else {
      setError("An Error Occurred.");
    }
  };

  return (
    <Card>
      <CardContent>
        <Box pb="1rem">
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
        <Typography variant="h4" align="center">
          {electricityPlanModel.type}
        </Typography>
        <br />
        <Typography variant="h6" align="center">
          {electricityPlanModel.description}
        </Typography>
        <br />
        <Typography>
          <b>Price</b>: {electricityPlanModel.price}$ Kw/h
        </Typography>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="primary"
          style={{ backgroundColor: "white" }}
          onClick={() => createBundle()}
        >
          Select
        </Button>
      </CardActions>
    </Card>
  );
};
export default ElectricityPlanCard;
