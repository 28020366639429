import { Box, Card, Typography } from "@mui/material";
import { format } from "date-fns";


export const SmallPayoutCard = ({payout}) => {
  const renderSwitch = ({currency, status}) => {
    switch (status) {
      case "SUCCESS":
        return <Typography fontWeight="bold" color="green">Received {currency}</Typography>;
      default:
        return <Typography fontWeight="bold" color="grey">{status}</Typography>;
    }
  };

  return (
    <Card>
      <Box
        display="flex"
        p="1rem"
      >
        <Box width="4rem" mr="1rem">
          <img src={payout.thumbnail} width="100%"/>
        </Box>
        <Box>
          {renderSwitch({currency: payout.currency, status: payout.status})}
          <Typography variant="h4">+ {Math.floor(payout.amount * 100000) / 100000} {payout.currency}</Typography>
          <Typography variant="h4">{payout.asic_model}</Typography>
          <Typography variant="h4">{format(Date.parse(payout.date), "dd/MM/yyyy HH:mm")}</Typography>
        </Box>
      </Box>
    </Card>
  );
};
