import { Box, useMediaQuery } from "@mui/material";
import AsicCard from "./AsicCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setAsics } from "../../state/MainSlice";
import { useCookies } from "react-cookie";
import { SmallAsicCard } from "./SmallAsicCard";
import NoAsics from "./NoAsics";

const AsicsPage = () => {
  const asics = useSelector((state) => state.eph.main.asics);
  const [cookies, _setCookie, _removeCookie] = useCookies([]);
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const idToken = useSelector((state) => state.eph.main.idToken);
  const [empty, setEmpty] = useState(false);

  async function getAsics() {
    const fetched_asics = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/asics/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          csrftoken: cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const asicsJson = await fetched_asics.json();
    asicsJson.length === 0 && setEmpty(true);
    dispatch(setAsics(asicsJson));
  }

  useEffect(() => {
    idToken !== "" && getAsics();
  }, [idToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="1rem" height="100%" sx={{ overflowY: "auto" }}>
      {empty ? (
        <NoAsics />
      ) : (
        <Box
          display="grid"
          gap="1rem"
          gridTemplateColumns="repeat(auto-fill, minmax(20rem, 1fr))"
          m="1rem"
        >
          {isNonMobile
            ? asics.map((asic) => (
                <AsicCard asic={asic} key={asic.id_asic_miner} />
              ))
            : asics.map((asic) => (
                <SmallAsicCard asic={asic} key={asic.id_asic_miner} />
              ))}
        </Box>
      )}
    </Box>
  );
};
export default AsicsPage;
