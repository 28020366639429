import { Button } from "@mui/material";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setBills } from "../../state/MainSlice";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { format } from "date-fns";

const BillsPage = () => {
  const [cookies, _setCookie, _removeCookie] = useCookies([]);
  const dispatch = useDispatch();
  const bills = useSelector((state) => state.eph.main.bills);
  const idToken = useSelector((state) => state.eph.main.idToken);

  const columns = [
    {
      field: "emission_datetime",
      headerName: "Emission Date",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return format(
          Date.parse(params.row.emission_datetime),
          "dd/mm/yyyy hh:mm"
        );
      },
    },
    {
      field: "payment_datetime",
      headerName: "Payment Date",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return format(
          Date.parse(params.row.payment_datetime),
          "dd/mm/yyyy hh:mm"
        );
      },
    },
    {
      field: "price",
      headerName: "Amount",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "invoice",
      headerName: "Invoice",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        const onClick = (e) => {
          const link = document.createElement("a");
          link.href = params.row.invoice;
          link.rel = "noopener noreferrer";
          link.target = "_blank";
          link.click();
        };
        return (
          <>
            <Button
              disabled={params.row.invoice === null}
              variant="outlined"
              color="warning"
              size="small"
              onClick={onClick}
            >
              View PDF
            </Button>
          </>
        );
      },
    },
  ];

  async function getBills() {
    const fetched_bills = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/bills/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          csrftoken: cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const billsJson = await fetched_bills.json();
    dispatch(setBills(billsJson));
  }

  useEffect(() => {
    idToken !== "" && getBills();
  }, [idToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DataGrid
        columns={columns}
        rows={bills}
        getRowId={(row) => row.id_electricity_bill}
        hideFooterPagination
        hideFooterSelectedRowCount
        sx={{ border: "none", width: "100%" }}
      />
    </>
  );
};
export default BillsPage;
