import { Button, Card, CardActions, CardContent, CardMedia, Typography, useTheme } from "@mui/material";

const AsicCard = ({asic}) => {
  const theme = useTheme();

  const openInNewTab = url => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const renderSwitch = (status) => {
    switch (status) {
      case "active":
        return <Typography fontWeight="bold" color="green">Active</Typography>;
      default:
        return <Typography fontWeight="bold" color="grey">{status}</Typography>;
    }
  };

  return (
    <Card sx={{p: "1rem"}}>
      <CardMedia
        component="img"
        height="200"
        image={asic.picture}
        alt="Asic Image"
      />
      <CardContent>
        {renderSwitch(asic.status)}
        <Typography><b>Model</b>: {asic.model}</Typography>
        <Typography><b>Serial number</b>: {asic.serial_number}</Typography>
        <Typography><b>Farm</b>: {asic.farm}</Typography>
        <Typography><b>Total Mined</b>: {Math.floor(asic.total_mined * 10000) / 10000} BTC</Typography>
      </CardContent>
      <CardActions>
        {asic.luxor_link && <Button onClick={() => openInNewTab(asic.luxor_link)}>See Status</Button>}
      </CardActions>
    </Card>
  );
};

export default AsicCard;
