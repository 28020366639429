import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Menu, MenuItem, Sidebar as ProSidebar, sidebarClasses, useProSidebar, } from "react-pro-sidebar";
import {
  Computer,
  ComputerOutlined,
  ElectricalServices,
  ElectricalServicesOutlined,
  GridView,
  GridViewOutlined,
  House,
  HouseOutlined,
  MenuOutlined,
  MonetizationOn,
  MonetizationOnOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../state/AuthSlice";
import { Item } from "./Item";

const Sidebar = () => {
  const {collapsed, collapseSidebar} = useProSidebar();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.pers.isSidebarOpen);
  const theme = useTheme();

  return (
    <ProSidebar
      defaultCollapsed={!isSidebarOpen}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: theme.palette.menu.background,
        },
        border: "none",
      }}
      sx={{
        height: "100%",
        position: "sticky",
        top: 0,
        overflowY: "visible"
      }}
    >
      <Menu
        menuItemStyles={{
          button: ({isSubmenu, level, disabled, active, open}) => ({
            backgroundColor: active ? theme.palette.menu.buttonActive : theme.palette.menu.background,
            "&:hover": {
              backgroundColor: theme.palette.menu.hover
            },
          }),
        }}
      >
        {/*Logo and Menu Icon*/}
        <MenuItem
          onClick={() => {
            collapseSidebar();
            dispatch(toggleSidebar());
          }}
          icon={collapsed ? <MenuOutlined sx={{color: theme.palette.menu.font}}/> : undefined}
          style={{
            margin: "10px 0 20px 0",
          }}
        >
          {!collapsed && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" color={theme.palette.menu.font}>Dashboard</Typography>
              <IconButton>
                <MenuOutlined sx={{color: theme.palette.menu.font}}/>
              </IconButton>
            </Box>
          )}
        </MenuItem>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box>
            <Item
              title="My Products"
              to="/products"
              iconActive={<Computer sx={{color: theme.palette.menu.font}}/>}
              iconInactive={<ComputerOutlined sx={{color: theme.palette.menu.font}}/>}
              showText={true}
            />
            <Item
              title="Farms"
              to="/farms"
              iconActive={<House sx={{color: theme.palette.menu.font}}/>}
              iconInactive={<HouseOutlined sx={{color: theme.palette.menu.font}}/>}
              showText={true}
            />
            <Item
              title="My Shared Miners"
              to="/shared"
              iconActive={<GridView sx={{color: theme.palette.menu.font}}/>}
              iconInactive={<GridViewOutlined sx={{color: theme.palette.menu.font}}/>}
              showText={true}
            />
            <Item
              title="Payouts"
              to="/payouts"
              iconActive={<MonetizationOn sx={{color: theme.palette.menu.font}}/>}
              iconInactive={<MonetizationOnOutlined sx={{color: theme.palette.menu.font}}/>}
              showText={true}
            />
            <Item
              title="My Bills"
              to="/bills"
              iconActive={<ElectricalServices sx={{color: theme.palette.menu.font}}/>}
              iconInactive={<ElectricalServicesOutlined sx={{color: theme.palette.menu.font}}/>}
              showText={true}
            />
          </Box>
        </Box>
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;
