import { CenteredBox } from "../../components/Styled";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const NoAsics = () => {
  const user = useSelector((state) => state.eph.main.user);
  const username = user.given_name || user.email;
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <CenteredBox>
      <Box
        display="flex"
        flexDirection="column"
        m="1rem"
        alignItems={isNonMobile ? "center" : "left"}
      >
        <Typography variant="h2">
          Hi <b>{username}</b>
        </Typography>
        <Typography variant="h2">
          Welcome to <b>Miner</b>Minds
        </Typography>
        <Typography variant="h4" mt="1rem">
          This is your <b>products page</b>. You can start buying{" "}
          <Link to="/farms">here</Link>
        </Typography>
      </Box>
    </CenteredBox>
  );
};
export default NoAsics;
