import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PlaceholderBox = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "2rem",
  p: "1rem",
});

const UserPage = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [cookies, _setCookie, _removeCookie] = useCookies([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [userInfo, setUserInfo] = useState({});
  const idToken = useSelector((state) => state.eph.main.idToken);
  const navigate = useNavigate();
  const onSubmit = (data) => console.log(data);

  const getUserInfo = async () => {
    const fetchedUserInfo = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/userinfo/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          csrftoken: cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const fetched = await fetchedUserInfo.json();
    setUserInfo(fetched);
  };

  useEffect(() => {
    idToken !== "" && getUserInfo();
  }, [idToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${isNonMobile ? 4 : 1}, minmax(0, 2fr))`}
        m="1rem"
        p="1rem"
        gap="1rem"
      >
        <PlaceholderBox>
          <Typography variant="h4">Username:</Typography>
          <TextField
            type="text"
            placeholder={userInfo.username}
            {...register("Username", {})}
          />
        </PlaceholderBox>
        <PlaceholderBox>
          <Typography variant="h4">First Name:</Typography>
          <TextField
            type="text"
            placeholder={userInfo.first_name}
            {...register("First name", {
              required: true,
              maxLength: 80,
            })}
          />
        </PlaceholderBox>
        <PlaceholderBox>
          <Typography variant="h4">Last Name:</Typography>
          <TextField
            type="text"
            placeholder={userInfo.last_name}
            {...register("Last name", {
              required: true,
              maxLength: 100,
            })}
          />
        </PlaceholderBox>
        <PlaceholderBox>
          <Typography variant="h4">Email:</Typography>
          <TextField
            type="text"
            placeholder={userInfo.email}
            {...register("Email", {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
          />
        </PlaceholderBox>
        <Button type="submit">Save</Button>
        <Button onClick={() => navigate("/change-password")}>
          Change Password
        </Button>
      </Box>
    </form>
  );
};

export default UserPage;
