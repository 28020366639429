import { CenteredBox } from "../../components/Styled";
import { Box, Typography } from "@mui/material";

const NoShares = () => {
  return <CenteredBox>
    <Box display="flex" flexDirection="column" gap="1rem" m="1rem" alignItems="center">
      <Typography variant="h3">This is your shares page.</Typography>
    </Box>
  </CenteredBox>;
};
export default NoShares;
