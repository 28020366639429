import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

export const Withdraw = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const idToken = useSelector((state) => state.eph.main.idToken);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: { cb1: false, cb2: false, btcAddress: "", amount: 0 },
  });

  const onSubmit = async (data) => {
    if (!(data.cb1 && data.cb2)) {
      alert("Must accept both checkboxes");
      return;
    }
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/withdraw/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRFToken": cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setSuccess("Withdraw petition successful. We will contact you shortly via email.");
    } else {
      setError("An Error Occurred.");
    }
  };

  return (
    <>
      <Box pb="1rem">
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
      </Box>
      <FormControl>
        <Box mx="1rem" gap="1rem" display="flex" flexDirection="column">
          <Box display="flex" flexDirection="column" gap="0.3rem">
            <TextField
              label="Destination BTC Address"
              onChange={(e) => setValue("btcAddress", e.target.value)}
            />
            <Typography>
              Please enter a valid BTC address, or you may permanently lose your
              BTC.
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap="0.3rem">
            <TextField
              type="number"
              label="Amount"
              onChange={(e) => setValue("amount", e.target.value)}
              inputProps={{
                maxLength: 13,
                step: "0.001",
              }}
            />
            <Typography>
              Enter an amount bigger than 10 euros, to cover for transaction
              fees.
            </Typography>
          </Box>
          <Box>
            <Typography>
              <b>Estimated transaction fee</b>
            </Typography>
            <Typography>
              The estimated network transaction fees can be found{" "}
              <a
                href="https://privacypros.io/tools/bitcoin-fee-estimator/"
                target="_blank"
              >
                here
              </a>
              , although the fee could be higher. Transactions typically go next
              block.
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={
                <Checkbox onChange={() => setValue("cb1", !getValues("cb1"))} />
              }
              label="I acknowledge that the above BTC address is correct, and that SplitShares is not responsible for an incorrect BTC address."
            />
            <FormControlLabel
              control={
                <Checkbox onChange={() => setValue("cb2", !getValues("cb2"))} />
              }
              label="I acknowledge that I have seen the estimated BTC network transaction fee from the link provided, and that the actual transaction fee may differ depending on the network."
            />
          </Box>
          <Button onClick={handleSubmit(onSubmit)}>Withdraw</Button>
        </Box>
      </FormControl>
    </>
  );
};
