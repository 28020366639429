import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AsicFarmCard from "./AsicFarmCard";
import { FarmStepper } from "./FarmStepper";
import { useSelector } from "react-redux";

const AsicFarmsPage = () => {
  const [asicModels, setAsicModels] = useState([]);
  const routeParams = useParams();
  const idToken = useSelector((state) => state.eph.main.idToken);
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const getFarmAsics = async () => {
    const fetchedAsics = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/farms/${routeParams.id_farm}/asic-farms`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          csrftoken: cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const fetched = await fetchedAsics.json();
    setAsicModels(fetched);
  };

  useEffect(() => {
    idToken !== "" && getFarmAsics();
  }, [idToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="1rem" height="100vh" sx={{ overflowY: "scroll" }}>
      <Box mb="1rem">
        <Typography variant="h3">Select Farm</Typography>
      </Box>
      <Box m="2rem">
        <FarmStepper stepNumber={1} />
      </Box>
      <Box
        display="grid"
        gap="1rem"
        gridTemplateColumns="repeat(auto-fill, minmax(20rem, 1fr))"
        p="1rem"
      >
        {asicModels.map((asic) => (
          <AsicFarmCard asicFarm={asic} key={asic.id_asic_farm} />
        ))}
      </Box>
    </Box>
  );
};

export default AsicFarmsPage;
