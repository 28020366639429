import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useNavigate, useParams } from "react-router-dom";


export const FarmStepper = ({stepNumber}) => {
  const navigate = useNavigate();
  const routeParams = useParams();

  return (
    <Box sx={{width: "100%"}}>
      <Stepper nonLinear activeStep={stepNumber}>
        <Step key="farm">
          <StepButton color="inherit" onClick={() => navigate("/farms")}>
            {"Select Farm"}
          </StepButton>
        </Step>
        <Step key="asic">
          <StepButton
            color="inherit"
            onClick={() =>
              stepNumber === 2 && navigate(`/farms/${routeParams.id_farm}/asic-farms`)}>
            {"Select ASIC Model"}
          </StepButton>
        </Step>
        <Step key="electricity">
          <StepButton color="inherit">
            {"Select Electricity Model"}
          </StepButton>
        </Step>
      </Stepper>
      <Box>
      </Box>
    </Box>
  );
};
