import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FarmCard = ({farm}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/farms/${farm.id_farm}/asic-farms`)
  }

  return (
    <Card sx={{filter: farm.active ? null : "grayscale(100%)"}}>
      <CardMedia
        component="img"
        image={farm.picture}
        alt="Farm Image"
        sx={{objectFit: "contain", maxHeight: "300px"}}
      />
      <CardContent>
        {farm.active && <>
          <Typography align="center" variant="h3"><b>{farm.name}</b></Typography>
          <br/>
          <Typography variant="h4"><b>Country</b>: {farm.country}</Typography>
          <Typography variant="h4"><b>Capacity filled</b>: {farm.capacity_filled}%</Typography>
          <Typography variant="h4"><b>Maintenance fee</b>: {farm.maintenance_fee}</Typography>
          <Typography variant="h4"><b>Electricity price</b>: {farm.public_electricity_price}$ Kw/h</Typography>
        </>
        }
        {!farm.active && <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography><b>Farm unavailable</b></Typography>
          </Box>
        </>
        }
      </CardContent>
      <CardActions sx={{display: "flex", justifyContent: "center"}}>
        {farm.active &&
          <Button
            color="primary"
            style={{"backgroundColor": "white"}}
            onClick={() => onClick()}
            disabled={!farm.active}
          >
            See ASICs</Button>
        }
      </CardActions>
    </Card>
  );
};

export default FarmCard;
