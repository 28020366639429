import FlexBetween from "./FlexBetween";
import { useTheme } from "@mui/material";
import { Item } from "./Item";
import {
  Computer,
  ComputerOutlined, ElectricalServices,
  ElectricalServicesOutlined, GridView,
  GridViewOutlined, House,
  HouseOutlined, MonetizationOn,
  MonetizationOnOutlined
} from "@mui/icons-material";
import { Menu, Sidebar as ProSidebar, sidebarClasses, } from "react-pro-sidebar";

export const BottomTabs = () => {
  const theme = useTheme();

  return <>
    <ProSidebar
      collapsed={true}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: theme.palette.menu.background,
        },
        border: "none",
      }}
    >
      <Menu
        menuItemStyles={{
          button: ({isSubmenu, level, disabled, active, open}) => ({
            backgroundColor: active ? theme.palette.menu.buttonActive : theme.palette.menu.background,
            "&:hover": {
              backgroundColor: theme.palette.menu.hover
            },
          }),
        }}
      >
        <FlexBetween
          position="fixed"
          bottom="0"
          width="100%"
          backgroundColor={theme.palette.menu.background}
        >
          <Item
            title="My Products"
            to="/products"
            iconActive={<Computer sx={{color: theme.palette.menu.font}}/>}
            iconInactive={<ComputerOutlined sx={{color: theme.palette.menu.font}}/>}
            showText={false}
          />
          <Item
            title="Farms"
            to="/farms"
            iconActive={<House sx={{color: theme.palette.menu.font}}/>}
            iconInactive={<HouseOutlined sx={{color: theme.palette.menu.font}}/>}
            showText={false}
          />
          <Item
            title="My Shared Miners"
            to="/shared"
            iconActive={<GridView sx={{color: theme.palette.menu.font}}/>}
            iconInactive={<GridViewOutlined sx={{color: theme.palette.menu.font}}/>}
            showText={false}
          />
          <Item
            title="Payouts"
            to="/payouts"
            iconActive={<MonetizationOn sx={{color: theme.palette.menu.font}}/>}
            iconInactive={<MonetizationOnOutlined sx={{color: theme.palette.menu.font}}/>}
            showText={false}
          />
          <Item
            title="My Bills"
            to="/bills"
            iconActive={<ElectricalServices sx={{color: theme.palette.menu.font}}/>}
            iconInactive={<ElectricalServicesOutlined sx={{color: theme.palette.menu.font}}/>}
            showText={false}
          />
        </FlexBetween>
      </Menu>
    </ProSidebar>
  </>;
};
