import { createTheme } from "@mui/material/styles";

export const shades = {
  "primary": {
    "100": "#eff7ff",
    "200": "#dfeaf9",
    "300": "#ceddf2",
    "400": "#7baaee",
    "500": "#2776ea",
    "600": "#144ea3",
    "700": "#00255b",
    "800": "#01204e",
    "900": "#011b41"
  },
  "neutral": {
    "50": "#ffffff",
    "100": "#f7f5f5",
    "200": "#edebeb",
    "300": "#e3e1e1",
    "400": "#d9d7d7",
    "500": "#cfcdcd",
    "600": "#c5c3c3",
    "700": "#bbb9b9",
    "800": "#b1afaf",
    "900": "#a7a5a5"
  }
};

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: shades.primary[900]
      },
      secondary: {
        main: shades.neutral[500]
      },
      neutral: {
        dark: shades.neutral[700],
        main: shades.neutral[50],
        light: shades.neutral[50]
      },
      background: {
        main: shades.neutral[100],
        default: shades.neutral[50],
        alt: shades.primary[200],
      },
      menu: {
        background: shades.primary[900],
        hover: shades.primary[700],
        buttonActive: shades.primary[700],
        font: shades.primary[100],
        fontDim: shades.neutral[200]
      }
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: 12,
      fontWeightRegular: "400",
      h1: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 48,
      },
      h2: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: "clamp(1rem, 2rem, 2.25rem)",
        fontWeight: "400"
      },
      h3: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 20,
        fontWeight: "400",
      },
      h4: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 14,
        fontWeight: "400"
      },
      h5: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 12,
        fontWeight: "400"
      },
      h6: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
        fontSize: 10,
        fontWeight: "400"
      },
    }
  }
);
