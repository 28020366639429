import { Box, Card, Typography } from "@mui/material";

export const SmallAsicCard = ({asic}) => {
  const renderSwitch = (status) => {
    switch (status) {
      case "active":
        return <Typography fontWeight="bold" color="green">Active</Typography>;
      default:
        return <Typography fontWeight="bold" color="grey">{status}</Typography>;
    }
  };

  return (
    <Card>
      <Box
        display="flex"
        p="1rem"
      >
        <Box width="6rem" mr="1rem">
          <img src={asic.thumbnail} width="100%"/>
        </Box>
        <Box>
          <Typography variant="h6">{asic.model}</Typography>
          <Typography variant="h4">{asic.serial_number}</Typography>
          <Typography variant="h4">{asic.farm}</Typography>
          <Typography><b>Total Mined</b>: {Math.floor(asic.total_mined * 10000) / 10000} BTC</Typography>
          {renderSwitch(asic.status)}
        </Box>
      </Box>
    </Card>
  )
}
