import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const AsicFarmCard = ({asicFarm}) => {
  const routeParams = useParams();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/farms/${routeParams.id_farm}/asic-farms/${asicFarm.id_asic_farm}/electricity-plans`);
  };

  return (
    <Card>
      <CardMedia
        component="img"
        height="300"
        image={asicFarm.picture}
        alt="Asic Model Image"
      />
      <CardContent>
        <Typography variant="h4" align="center">{asicFarm.model}</Typography>
        <br/>
        <Typography><b>Hashrate</b>: {asicFarm.hashrate} TH/s</Typography>
        <Typography><b>Power Usage</b>: {asicFarm.power} Watts</Typography>
        <Typography>
          <b>Estimated installation time: </b>
          {asicFarm.estimated_total_installation_time} days</Typography>
        <Typography><b>Warranty</b>: 1 year</Typography>
        <Typography><b>Price</b>: ${asicFarm.price}</Typography>
      </CardContent>
      <CardActions sx={{display: "flex", justifyContent: "center"}}>
        <Button
          color="primary"
          style={{"backgroundColor": "white"}}
          onClick={() => onClick()}
        >
          Select</Button>
      </CardActions>
    </Card>
  );
};
export default AsicFarmCard;
