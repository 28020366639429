import { Box, Button, Card, Typography } from "@mui/material";

const SharedAsicCard = ({share}) => {
  const openInNewTab = url => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Card>
      <Box
        display="flex"
        p="1rem"
      >
        <Box width="6rem" mr="1rem">
          <img src={share.thumbnail} width="100%"/>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography><b>Share Number</b>: #{share.share_number}</Typography>
          <Typography><b>Model</b>: {share.model}</Typography>
          <Typography><b>Total Mined</b>: {Math.floor(share.total_mined * 1000000) / 1000000} BTC</Typography>
          <Box>
            {share.luxor_link && <Button onClick={() => openInNewTab(share.luxor_link)}>See ASIC</Button>}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default SharedAsicCard;
