import { Alert, Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import logo from "../../assets/minerminds-logo.png";
import { Auth0LoginButton } from "../../auth/Auth0LoginButton";
import { SignupButton } from "../../auth/SignupButton";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, _setCookie, _removeCookie] = useCookies([]);

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" flexDirection="row" maxWidth="24rem" flex={1}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box textAlign="center" m="0 0 1rem 0">
            <img src={logo} alt="Minerminds Logo" />
          </Box>
          <Typography variant="h3" textAlign="center">
            Welcome to
          </Typography>
          <Typography variant="h5" m="0 0 1rem 0" textAlign="center">
            MinerMinds Dashboard
          </Typography>
          <Box
            mt="1rem"
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <Auth0LoginButton />
            <SignupButton />
          </Box>
          <Box mt="1rem">
            <Alert severity="info">
              Note: You need to confirm your email to be able to log in.
            </Alert>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
