import "./App.css";
import LoginPage from "./scenes/login/LoginPage";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box, CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import Sidebar from "./components/Sidebar";
import { ProSidebarProvider } from "react-pro-sidebar";
import { CookiesProvider } from "react-cookie";
import AsicsPage from "./scenes/asics/AsicsPage";
import FarmsPage from "./scenes/farms/FarmsPage";
import { useDispatch } from "react-redux";
import AsicFarmsPage from "./scenes/farms/AsicFarmsPage";
import BillsPage from "./scenes/bills/BillsPage";
import SharedPage from "./scenes/shared/SharedPage";
import { BottomTabs } from "./components/BottomTabs";
import PayoutsPage from "./scenes/payouts/PayoutsPage";
import { ChangePasswordPage } from "./scenes/settings/ChangePasswordPage";
import ElectricityPlansPage from "./scenes/farms/ElectricityPlansPage";
import { CartPage } from "./scenes/cart/CartPage";
import { CheckoutPage } from "./scenes/cart/CheckoutPage";
import { PaymentSuccess } from "./scenes/cart/PaymentSuccess";
import { theme } from "./theme";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "./components/Loading";
import { TopBar } from "./components/TopBar";
import { useEffect } from "react";
import { setIdToken, setUser } from "./state/MainSlice";
import UserPage from "./scenes/settings/UserPage";
import {Withdraw} from "./scenes/cart/Withdraw";

function App() {
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      if (claims) {
        const idToken = claims.__raw;
        dispatch(setIdToken(idToken));
      }
    });
    if (user) {
      dispatch(setUser(user));
    }
  }, [isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <CookiesProvider>
          <ProSidebarProvider>
            <div className="app">
              {isAuthenticated && isNonMobile && <Sidebar />}
              <main
                className="content"
                style={{ backgroundColor: theme.palette.background.main }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  height="100%"
                  backgroundColor={theme.palette.background.main}
                >
                  {isAuthenticated && <TopBar />}
                  <>
                    <Routes>
                      {!isAuthenticated && (
                        <>
                          <Route path="/login" element={<LoginPage />} />
                        </>
                      )}
                      {isAuthenticated && (
                        <>
                          <Route
                            path="/"
                            element={<Navigate to="/products" />}
                          />
                          <Route path="/products" element={<AsicsPage />} />
                          <Route path="/farms" element={<FarmsPage />} />
                          <Route path="/withdraw" element={<Withdraw />} />
                          <Route
                            path="/farms/:id_farm/asic-farms"
                            element={<AsicFarmsPage />}
                          />
                          <Route
                            path="/farms/:id_farm/asic-farms/:id_asic_farm/electricity-plans"
                            element={<ElectricityPlansPage />}
                          />
                          <Route path="/payouts" element={<PayoutsPage />} />
                          <Route path="/bills" element={<BillsPage />} />
                          <Route path="/shared" element={<SharedPage />} />
                          <Route
                            path="/change-password"
                            element={<ChangePasswordPage />}
                          />
                          <Route path="/cart" element={<CartPage />} />
                          <Route path="/checkout" element={<CheckoutPage />} />
                          <Route
                            path="/payment-success"
                            element={<PaymentSuccess />}
                          />
                        </>
                      )}
                      <Route
                        path="/*"
                        element={
                          isAuthenticated ? (
                            <Navigate to="/products" />
                          ) : (
                            <Navigate to="/login" />
                          )
                        }
                      />
                    </Routes>
                  </>
                  {isAuthenticated && !isNonMobile && <BottomTabs />}
                  {isAuthenticated && !isNonMobile && <Box height="4rem" />}
                </Box>
              </main>
            </div>
          </ProSidebarProvider>
        </CookiesProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
