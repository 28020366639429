import { useAuth0 } from "@auth0/auth0-react";
import { Button, useTheme } from "@mui/material";

export const Auth0LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { palette } = useTheme();

  return (
    <Button
      sx={{
        backgroundColor: palette.primary.main,
        color: palette.neutral.light,
        "&:hover": {color: palette.primary.main},
      }}
      onClick={async () =>
        await loginWithRedirect()
      }
    >
      Login
    </Button>
  );
};
