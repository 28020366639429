import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FarmStepper } from "./FarmStepper";
import ElectricityPlanCard from "./ElectricityPlanCard";
import { useSelector } from "react-redux";

const ElectricityPlansPage = () => {
  const [electricityPlans, setElectricityPlans] = useState([]);
  const routeParams = useParams();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const idToken = useSelector((state) => state.eph.main.idToken);

  const getElectricityPlans = async () => {
    const fetchedPlans = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/farms/${routeParams.id_farm}/electricity-plan-models`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          csrftoken: cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const fetched = await fetchedPlans.json();
    setElectricityPlans(fetched);
  };

  useEffect(() => {
    idToken !== "" && getElectricityPlans();
  }, [idToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="1rem" height="100vh" sx={{ overflowY: "scroll" }}>
      <Box mb="1rem">
        <Typography variant="h3">Select Farm</Typography>
      </Box>
      <Box m="2rem">
        <FarmStepper stepNumber={2} />
      </Box>
      <Box
        display="grid"
        gap="1rem"
        gridTemplateColumns="repeat(auto-fill, minmax(20rem, 1fr))"
        p="1rem"
      >
        {electricityPlans.map((electricityPlan) => (
          <ElectricityPlanCard
            electricityPlanModel={electricityPlan}
            key={electricityPlan.id_electricity_plan_model}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ElectricityPlansPage;
