import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist/es/constants";
import { Provider } from "react-redux";
import authReducer from "./state/AuthSlice";
import mainReducer from "./state/MainSlice";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./auth/Auth0ProviderWithNavigate";

const persistConfig = {key: "root", storage, version: 1};
const ephemeralReducer = combineReducers({
  main: mainReducer,
});
const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {pers: persistedReducer, eph: ephemeralReducer},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<>
    <React.StrictMode>
      <title>Minerminds Dashboard</title>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)}>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <App/>
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </>
);
