import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  isAuth: false,
  isSidebarOpen: true,
  selectedPage: "Products",
};

export const authSlice = createSlice(
  {
    name: "auth",
    initialState,
    reducers: {
      setLogin: (state, action) => {
        state.isAuth = true;
      },
      setLogout: (state, action) => {
        state.isAuth = false;
      },
      toggleSidebar: (state, action) => {
        state.isSidebarOpen = !state.isSidebarOpen;
      },
      setSelectedPage: (state, action) => {
        state.selectedPage = action.payload;
      },
    }
  }
);

export const {
  setLogin,
  setLogout,
  toggleSidebar,
  setSelectedPage,
} = authSlice.actions;
export default authSlice.reducer;
