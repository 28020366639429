import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Typography, useTheme } from "@mui/material";
import { setSelectedPage } from "../state/AuthSlice";

export const Item = ({title, to, iconActive, iconInactive, showText}) => {
  const dispatch = useDispatch();
  const selectedPage = useSelector((state) => state.pers.selectedPage);
  const active = selectedPage === title;
  const theme = useTheme();

  return (
    <MenuItem
      component={<Link to={to}/>}
      active={active}
      onClick={() => {
        dispatch(setSelectedPage(title));
      }}
      icon={active ? iconActive : iconInactive}
    >
      {showText && <Typography variant="h4" color={theme.palette.menu.font}>{title}</Typography>}
    </MenuItem>
  );
};
