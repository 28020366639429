import { Box, Button, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useState } from "react";

export const CheckoutPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const { palette } = useTheme();
  const cart = useSelector((state) => state.eph.main.cart);
  const idToken = useSelector((state) => state.eph.main.idToken);
  const [error, setError] = useState("");

  const onClick = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/payments/coinbase/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRFToken": cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const body = await response.json();
    if (response.ok) {
      const link = document.createElement("a");
      link.href = body.redirect_url;
      link.rel = "noopener noreferrer";
      link.target = "_self";
      link.click();
    } else {
      setError("An error occurred");
    }
  };

  return (
    <Box m="1rem" p="1rem">
      <Typography variant="h3">You are about to pay {cart.total}$</Typography>
      <Typography variant="h3">Please select a method of payment</Typography>
      <Button
        size="large"
        onClick={() => onClick()}

        sx={{
          backgroundColor: palette.primary.main,
          color: palette.neutral.light,
          mt: "1rem",
          "&:hover": { color: palette.primary.main },
        }}
      >
        Coinbase
      </Button>
    </Box>
  );
};
