import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { BundleCard } from "./BundleCard";
import { useDispatch, useSelector } from "react-redux";
import { setBundles } from "../../state/MainSlice";
import { useNavigate } from "react-router-dom";

export const CartPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [isLoading, setIsLoading] = useState(true);
  const cart = useSelector((state) => state.eph.main.cart);
  const idToken = useSelector((state) => state.eph.main.idToken);

  const getBundles = async () => {
    const fetchedBundles = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/bundles/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          csrftoken: cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const fetched = await fetchedBundles.json();
    dispatch(setBundles(fetched));
    setIsLoading(false);
  };

  useEffect(() => {
    idToken !== "" && getBundles();
  }, [idToken, isLoading]);

  return (
    <Box m="1rem" sx={{ overflowY: "auto" }}>
      <Box mb="1rem">
        <Card>
          <CardContent>
            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Typography variant="h2">Total: {cart.total}$</Typography>
                </Box>
                <Button
                  size="large"
                  onClick={() => navigate("/checkout")}
                  disabled={cart.total === 0}
                  sx={cart.total !== 0 && {
                    backgroundColor: palette.primary.main,
                    color: palette.neutral.light,
                    "&:hover": { color: palette.primary.main },
                  }}
                >
                  Checkout
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
      <Box
        display="grid"
        gap="1rem"
        gridTemplateColumns="repeat(auto-fill, minmax(20rem, 1fr))"
      >
        {cart.bundles.map((bundle) => (
          <BundleCard
            bundle={bundle}
            setIsLoading={setIsLoading}
            key={bundle.id}
          />
        ))}
      </Box>
    </Box>
  );
};
