import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { useSelector } from "react-redux";

export const BundleCard = ({ bundle, setIsLoading }) => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const idToken = useSelector((state) => state.eph.main.idToken);

  const removeBundle = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/bundles/${bundle.id}/`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "X-CSRFToken": cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json"
        },
      }
    );
    if (response.ok) {
      setSuccess("Bundle Removed.");
    } else {
      setError("An Error Occurred.");
    }
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" flexDirection="column" gap="0.2rem">
          <Typography variant="h4" align="center">
            {bundle.farm}
          </Typography>
          <Typography variant="h6" align="center">
            {bundle.asic_model}
          </Typography>
          <Typography variant="h6" align="center">
            {bundle.electricity_plan_model}
          </Typography>
          <Typography variant="h4" align="center">
            Price: {bundle.price}
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="primary"
          style={{ backgroundColor: "white" }}
          onClick={() => {
            removeBundle();
            setIsLoading(true);
          }}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};
