import { styled } from "@mui/system";

export const CenteredBox = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

