import { Box, useTheme } from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";

export const Loading = () => {
  const { palette } = useTheme();

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <ThreeCircles color={palette.primary.main} />
    </Box>
  );
};
