import { Alert, Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { useSelector } from "react-redux";

export const ChangePasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const idToken = useSelector((state) => state.eph.main.idToken);

  const onSubmit = async (data, e) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/password/change/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRFToken": cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
        body: JSON.stringify(data),
      }
    );
    const body = await response.json();
    if (response.ok) {
      setSuccess(body.detail);
      setError("");
    } else {
      setError(body.new_password2);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          placeholder="New Password"
          {...register("new_password1")}
        ></TextField>
        <TextField
          placeholder="Repeat New Password"
          {...register("new_password2")}
        ></TextField>
        <Button type="submit">Send</Button>
      </form>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
    </Box>
  );
};
