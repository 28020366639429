import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button, useTheme } from "@mui/material";

export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { palette } = useTheme();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/products",
      },
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  return (
    <Button
      sx={{
        backgroundColor: palette.primary.main,
        color: palette.neutral.light,
        "&:hover": { color: palette.primary.main },
      }}
      onClick={handleSignUp}
    >
      Sign Up
    </Button>
  );
};
