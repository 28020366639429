import { Box, Typography } from "@mui/material";

export const PaymentSuccess = () => {
  return (
    <Box m="1rem" p="1rem">
      <Box>
        <Typography variant="h3">Your payment was successful!</Typography>
      </Box>
    </Box>
  );
};
