import { Box, useMediaQuery } from "@mui/material";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { format } from "date-fns";
import { setPayouts } from "../../state/MainSlice";
import { SmallPayoutCard } from "./SmallPayoutCard";

const PayoutsPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const dispatch = useDispatch();
  const payouts = useSelector((state) => state.eph.main.payouts);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const idToken = useSelector((state) => state.eph.main.idToken);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return format(Date.parse(params.row.date), "dd/MM/yyyy HH:mm");
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "currency",
      headerName: "Currency",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "asic_model",
      headerName: "Asic Model",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "serial_number",
      headerName: "Serial Number",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
  ];

  async function getPayouts() {
    const fetched_payouts = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/payouts/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          csrftoken: cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const payoutsJson = await fetched_payouts.json();
    dispatch(setPayouts(payoutsJson));
  }

  useEffect(() => {
    idToken !== "" && getPayouts();
  }, [idToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isNonMobile ? (
        <DataGrid
          columns={columns}
          rows={payouts}
          getRowId={(row) => row.id}
          hideFooterPagination
          hideFooterSelectedRowCount
          sx={{ border: "none", width: "100%" }}
        />
      ) : (
        <Box>
          {payouts.map((payout) => (
            <SmallPayoutCard payout={payout} key={payout.id} />
          ))}
        </Box>
      )}
    </>
  );
};
export default PayoutsPage;
