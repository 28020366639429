import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import FarmCard from "./FarmCard";
import { FarmStepper } from "./FarmStepper";

const FarmsPage = () => {
  const [farms, setFarms] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies([]);

  useEffect(() => {
    const getFarms = async () => {
      const fetchedFarms = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/farms/`,
        {
          method: "GET",
          credentials: "include",
          headers: {"csrftoken": cookies["csrftoken"]}
        }
      );
      const fetched = await fetchedFarms.json();
      setFarms(fetched);
    };
    getFarms();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (<Box
      m="1rem"
      height="100vh"
      sx={{overflowY: "auto"}}
    >
      <Box mb="1rem">
        <Typography variant="h3">Select Farm</Typography>
      </Box>
      <Box m="2rem">
        <FarmStepper stepNumber={0}/>
      </Box>
      <Box
        display="grid"
        gap="1rem"
        gridTemplateColumns="repeat(auto-fill, minmax(20rem, 1fr))"
      >
        {farms.map(farm => (
          <FarmCard farm={farm} key={farm.id_farm}/>
        ))}
      </Box>
    </Box>
  );
};

export default FarmsPage;
