import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetween from "./FlexBetween";
import {
  LogoutOutlined, PersonOutlined, SavingsOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { useCookies } from "react-cookie";
import {
  setLogoutModalOpen,
  setTotalMined,
} from "../state/MainSlice";
import { LogoutModal } from "./LogoutModal";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { setSelectedPage } from "../state/AuthSlice";

export const TopBar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobileScreen = useMediaQuery("(min-width: 600px)");
  const open = useSelector((state) => state.eph.main.isLogoutModalOpen);
  const totalMined = useSelector((state) => state.eph.main.totalMined);
  const idToken = useSelector((state) => state.eph.main.idToken);
  const { logout } = useAuth0();

  const handleShoppingCart = () => {
    dispatch(setSelectedPage("ShoppingCart"));
    navigate("/cart");
  };
  const handleClose = () => dispatch(setLogoutModalOpen(false));
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const isShoppingCartActive = useSelector(
    (state) => state.eph.main.selectedPage === "ShoppingCart"
  );

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_BASE_URL + "/login",
      },
    });
  };

  async function fetchTotal() {
    const fetchedTotal = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/total-mined/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          csrftoken: cookies["csrftoken"],
          Authorization: "Bearer " + idToken,
        },
      }
    );
    const totalJson = await fetchedTotal.json();
    if (fetchedTotal.status === 401) {
      handleLogout();
    }
    totalJson[0] && dispatch(setTotalMined(totalJson[0]));
  }

  useEffect(() => {
    idToken !== "" && fetchTotal();
  }, [idToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FlexBetween
        padding="1rem 2rem"
        position="sticky"
        top="0"
        height="4rem"
        backgroundColor={theme.palette.menu.background}
        zIndex={1}
      >
        <FlexBetween width="100%">
          {isNonMobileScreen ? (
            <Typography
              variant="h2"
              sx={{
                color: theme.palette.menu.fontDim,
                "&:hover": {
                  color: theme.palette.menu.font,
                  cursor: "pointer",
                },
              }}
            >
              <b>Miner</b>Minds
            </Typography>
          ) : (
            <Box display="flex">
              {/*<img src={logo} alt="Minerminds Logo"/>*/}
              <Typography variant="h3" color={theme.palette.menu.font}>
                <b>Miner</b>Minds
              </Typography>
            </Box>
          )}
          <Box
            display="flex"
            gap="1rem"
            p="1rem"
            color={theme.palette.menu.font}
          >
            <Typography variant="h4">
              {Math.floor(totalMined.btc * 1000000) / 1000000} BTC
            </Typography>
            <Typography variant="h4">
              {Math.floor(totalMined.kda * 1000000) / 1000000} KDA
            </Typography>
          </Box>
          <Box display="flex" gap="0.5rem">
            <IconButton onClick={() => navigate("/withdraw")}>
              <SavingsOutlined sx={{ color: theme.palette.menu.font }} />
            </IconButton>
            <IconButton onClick={handleShoppingCart}>
              {isShoppingCartActive ? (
                <ShoppingCart sx={{ color: theme.palette.menu.font }} />
              ) : (
                <ShoppingCartOutlined sx={{ color: theme.palette.menu.font }} />
              )}
            </IconButton>
            <IconButton onClick={() => dispatch(setLogoutModalOpen(true))}>
              <LogoutOutlined sx={{ color: theme.palette.menu.font }} />
            </IconButton>
          </Box>
        </FlexBetween>
      </FlexBetween>
      <LogoutModal open={open} onClose={handleClose} />
    </>
  );
};
